import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const PageHeader = () => {
  const [isOpaque, setIsOpaque] = useState();

  const updateHeaderColor = () => setIsOpaque(Boolean(window.scrollY));

  const addScrollListener = useCallback(() => {
    window.addEventListener('scroll', updateHeaderColor);
  }, []);

  const removeScrollListener = useCallback(() => {
    window.removeEventListener('scroll', updateHeaderColor);
  }, []);

  useEffect(addScrollListener, [addScrollListener]);
  useEffect(() => removeScrollListener, [removeScrollListener]);

  const headerClassNames = classNames(styles.header, { [styles.opaque]: isOpaque });

  return (
    <header className={headerClassNames}>
      <div className={styles.leftContent}>
        <a className={styles.pageIcon} href="/">GM</a>
      </div>

      <div className={styles.rightContent} />
    </header>
  );
};

export default PageHeader;
