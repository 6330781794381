import React from 'react';
import classNames from 'classnames';

import myPhoto from 'assets/myPhoto.jpg';

import { EDUCATION_SECTION_ID } from '../Education';
import styles from './styles.module.scss';

const Presentation = () => (
  <section className={styles.presentation}>
    <div className={styles.introduction}>
      Hey, my name is Gabriel.
      <br />
      I&apos;m a full-stack developer living in Belo Horizonte, Brazil
    </div>
    <img className={styles.myPhoto} src={myPhoto} alt="Gabriel Marques" />

    <div className={styles.icons}>
      <a className={styles.sectionLink} href={`#${EDUCATION_SECTION_ID}`}>
        <i className={classNames('fas fa-user-graduate', styles.icon)} />
        <span className={styles.text}>Education</span>
      </a>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={styles.sectionLink} disabled>
        <i className={classNames('fas fa-suitcase', styles.icon)} />
        <i className={classNames('fas fa-tools', styles.wrenchIcon)} />
        <span className={styles.text}>Coming soon</span>
      </a>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={styles.sectionLink} disabled>
        <i className={classNames('fas fa-globe-americas', styles.icon)} />
        <i className={classNames('fas fa-tools', styles.wrenchIcon)} />
        <span className={styles.text}>Coming soon</span>
      </a>
    </div>
  </section>
);

export default Presentation;
