import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toUpper } from 'lodash';

import styles from './styles.module.scss';

const NETWORKS = {
  github: 'github',
  linkedin: 'linkedin',
};

const SocialMediaLink = ({ href, network }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i
      className={classNames(`fab fa-${network}`, styles.socialMediaIcon)}
      alt={toUpper(network)}
    />
  </a>
);

SocialMediaLink.propTypes = {
  href: PropTypes.string.isRequired,
  network: PropTypes.oneOf(Object.values(NETWORKS)).isRequired,
};

export default SocialMediaLink;
export { NETWORKS };
