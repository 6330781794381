import React from 'react';

import styles from './styles.module.scss';
import SocialMediaLink, { NETWORKS } from '../SocialMediaLink';

const Footer = () => (
  <footer className={styles.footer}>
    <SocialMediaLink
      href="https://github.com/GabrielGAM25"
      network={NETWORKS.github}
      alt="Github"
    />

    <SocialMediaLink
      href="https://www.linkedin.com/in/gabrielmarques-dev"
      network={NETWORKS.linkedin}
      alt="LinkedIn"
    />
  </footer>
);

export default Footer;
