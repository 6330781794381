import React from 'react';

import Education from './Education';
import Presentation from './Presentation';
import styles from './styles.module.scss';

const Body = () => (
  <main className={styles.body}>
    <Presentation />
    <Education />
  </main>
);

export default Body;
