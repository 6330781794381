import React from 'react';

import PageHeader from 'shared/PageHeader';

import Body from './components/Body';
import Footer from './components/Footer';
import styles from './styles.module.scss';

const Home = () => (
  <div className={styles.container}>
    <PageHeader />
    <Body />
    <Footer />
  </div>
);

export default Home;
