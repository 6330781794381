import React from 'react';

import cefetLogo from 'assets/cefet.png';
import ufmgLogo from 'assets/ufmg.png';

import styles from './styles.module.scss';

const EDUCATION_SECTION_ID = 'education_section';

const Education = () => (
  <section
    id={EDUCATION_SECTION_ID}
    className={styles.education}
  >
    Education

    <div className={styles.institution}>
      <div className={styles.text}>
        <span className={styles.title}>
          Federal Center for Technological Education of Minas Gerais
        </span>

        <span className={styles.course}>
          Junior College: Informatics and Programming (2016 - 2018)
        </span>

        <span className={styles.description}>
          {/* eslint-disable-next-line max-len */}
          I took my first steps into the world of programming during my Informatics and Programming course at CEFET-MG. There, I learned a bit of Java, participating in a project to create an API for a gym management system. When I finished my course, I was sure that programming was exactly what I loved to do
        </span>
      </div>
      <img
        className={styles.cefetLogo}
        src={cefetLogo}
        alt="CEFET-MG"
      />
    </div>

    <div className={styles.institution}>
      <img
        className={styles.cefetLogo}
        src={ufmgLogo}
        alt="UFMG"
      />

      <div className={styles.text}>
        <span className={styles.title}>
          Federal University of Minas Gerais
        </span>

        <span className={styles.course}>
          Bachelor of Science: Information Systems (2019 - current)
        </span>

        <span className={styles.description}>
          {/* eslint-disable-next-line max-len */}
          In 2019, I joined UFMG willing to get a more solid basis on some programming concepts. Sometimes it&apos;s hard to conciliate the university with my job, but I know that it&apos;s completely worth the stress. I met some of the most renowned teachers from Brazil there, what makes me believe that I couldn&apos;t make a better decision other than start this course.
        </span>
      </div>
    </div>
  </section>
);

export { EDUCATION_SECTION_ID };

export default Education;
